import React from 'react'
import Helmet from 'react-helmet'
import Button from '../components/button'
import Logo from '../images/Logo.svg';
import './coming_soon.css'

const ComingSoonPage = () => {
  return (
    <div className='background'>
      <Helmet>
        <body className='coming-soon-body' />
        <title>Motorbooks - Em Breve</title>
      </Helmet>
      <img src={Logo} alt='Logo Motorbooks' width={170} height={200} />

      <h1 className='blue-text'>Em Breve a Maior Plataforma de Motores</h1>
      <p>
        São mais de 130 mil informações técnicas, <br />
        medidas e especificações de motores à diesel, <br />
        álcool, gasolina, linha náutica, geradores e motos!
      </p>
      <Button text='Saiba Mais' linkTo='/' />
    </div>
  )
}

export default ComingSoonPage
